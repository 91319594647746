import React from "react";
import starIcon from "../../assets/images/star-icon.png";
import { StaticImage } from "gatsby-plugin-image";

const OurSolutions = () => {
  return (
    <section className="solutions-area pb-70 pt-70 bg-f1f8fb">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} style={{ width: 30 }} alt="icon" />
            Predictable Income
          </span>
          <h2>Staking Income</h2>
          <p>
            HYDRA has a reliable and attractive staking economy. If the transactional economy is small, fixed block rewards will dominate during the growth phase and incentivize nodes to keep the network running. If the transactional economy is strong, deflation will kick in, and the supply will
            start shrinking.
          </p>
          <p>
            <strong>Block rewards consist of two main pillars:</strong> Transaction fees and new HYDRA issued by the blockchain. Their share can be adjusted by democratic votes on the decentralized governance protocol.
          </p>
        </div>

        <div className="row">
          <div className="col-lg-6 mb-5 mb-sm-0 col-sm-6">
            <div className="single-solutions-box shadow">
              <div className="icon text-center">
                <StaticImage placeholder="blurred" className="mx-auto" layout="fixed" width={80} height={80} src="../../assets/images/icons/transaction.png" alt="Assets on-chain" />
              </div>
              <h3>
                <a rel="noreferrer" target="_blank" href="https://docs.hydrachain.org/hydra-economy/staking-income-or-high-apy#block-rewards-transactional-economy">
                  Block Rewards - Transactional Economy
                </a>
              </h3>
              <p>Every transaction on the HYDRA chain requires a transaction fee. The fee policy is regularly voted on by coin holders and the result of these votes is binding for all network participants. This way the transactional economy on the chain can be steered by the network owners.</p>

              <a rel="noreferrer" href="https://docs.hydrachain.org/hydra-economy/staking-income-or-high-apy#block-rewards-transactional-economy" className="view-details-btn">
                View Details
              </a>
              <div className="alert alert-primary" style={{ position: "absolute", left: 0, bottom: -70, width: "100%" }}>
                <strong>Current Setting: </strong>100% Burn of Transaction Fees
              </div>
            </div>
          </div>

          <div className="col-lg-6 mt-5 mt-sm-0 col-sm-6">
            <div className="single-solutions-box shadow">
              <div className="icon text-center">
                <StaticImage placeholder="blurred" className="mx-auto" layout="fixed" width={80} height={80} src="../../assets/images/icons/minting-money.png" alt="Assets on-chain" />
              </div>
              <h3>
                <a target="_blank" rel="noreferrer" href="https://docs.hydrachain.org/hydra-economy/staking-income-or-high-apy#block-rewards-minted-hydra">
                  Block Rewards - Minted HYDRA
                </a>
              </h3>
              <p>As a second layer of revenue, the chain is rewarding block validators with newly minted HYDRA coins. This means that even in the case of 0 transactions on-chain, stakers will still be able to rely on an attractive APR.</p>

              <a target="_blank" rel="noreferrer" href="https://docs.hydrachain.org/hydra-economy/staking-income-or-high-apy#block-rewards-minted-hydra" className="view-details-btn">
                View Details
              </a>

              <div className="alert alert-primary" style={{ position: "absolute", left: 0, bottom: -70, width: "100%" }}>
                <strong>Current Setting: </strong>20% Inflation Rate Minted to Stakers
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurSolutions;
