import React from "react";
import { Link } from "gatsby";

import setupStaking from "../../assets/images/setup-staking.png";

import CircleShape from "../../assets/images/shape/circle-shape1.png";

const StartProjectTwo = () => {
  return (
    <div className="project-start-area ptb-100 bg-f1f8fb">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="project-start-image">
              <img src={setupStaking} alt="image" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2>Get Started with Staking</h2>
              <p>Get started by walking through our in-depth documentations and guides on how to get started with staking by becoming a node on the Hydra network.</p>

              <Link to="/becoming-a-node" className="default-btn">
                <i className="flaticon-web"></i>
                Get Started
                <span></span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="circle-shape1">
        <img src={CircleShape} alt="image" />
      </div>
    </div>
  );
};

export default StartProjectTwo;
