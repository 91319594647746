import React from "react";
import starIcon from "../../assets/images/star-icon.png";
import shape1 from "../../assets/images/shape/circle-shape1.png";
import { StaticImage } from "gatsby-plugin-image";

const AboutUsContent = () => {
  return (
    <section className="about-area ptb-100">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-image p-5">
              <StaticImage placeholder="blurred" src="../../assets/images/new-staking-economy.png"></StaticImage>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="content">
                <span className="sub-title">
                  <img style={{ width: 30 }} src={starIcon} alt="banner" />
                  Stability
                </span>
                <h2>The New Staking Economy</h2>
                <p>
                  HYDRA has a unique staking economy which guarantees fixed income to the stakers. Every block yields a pre-defined block reward, which can be adjusted by distributed governance proposals &amp; subsequent on-chain votes. The more HYDRA you stake, the more frequent you will mine
                  blocks. If the number of stakers decreases, then this will immediately increase the staking yield and therefore incentivize the deployment of new nodes by new stakers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="circle-shape1">
        <img src={shape1} style={{ width: 30 }} alt="banner" />
      </div>

      <div className="circle-shape1">
        <img src={shape1} alt="banner" />
      </div>
    </section>
  );
};

export default AboutUsContent;
