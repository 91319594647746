import { Link } from "gatsby";
import React, { useState } from "react";
import useFetch from "use-http";

const SimpleCalculator = () => {
  const {
    loading: loadingHydraInfo,
    error: errorHydraInfo,
    data: hydraInfo,
  } = useFetch("https://explorer.hydrachain.org/api/info", null, []);
  const {
    loading: loadingHydraPrice,
    error: errorHydraPrice,
    data: hydraPrice,
  } = useFetch(
    "https://api.coingecko.com/api/v3/simple/price?ids=hydra&vs_currencies=usd",
    null,
    []
  );

  const [hydraStaked, setHydraStaked] = useState(null);
  const netStakedWeight = ((hydraInfo?.circulatingSupply || 0) - 560000) * 0.94;
  const apy =
    ((parseFloat(hydraInfo?.supply || 0) * 0.2) / netStakedWeight) * 100;
  const dailyIncome = (hydraStaked * apy) / 365 / 100;
  const dailyIncomeInUSD = dailyIncome * (hydraPrice?.hydra?.usd || 0);

  return (
    <div className="bg-f1f8fb" style={{ overflow: "visible" }}>
      <div className="container" style={{ overflow: "visible" }}>
        <div className="row">
          <div className="col-md-12" style={{ overflow: "visible" }}>
            <div
              className="card shadow"
              style={{
                position: "relative",
                maxWidth: 900,
                zIndex: 100,
                top: -10,
                margin: "auto",
              }}
            >
              <div className="card-header bg-light m-0">
                <h5 className="m-0">Quick Income Calculator </h5>
              </div>
              <div className="card-body">
                <div className="row pb-3">
                  <div className="col-md-6">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <h6>How many HYDRA are you planning to stake?</h6>

                        <div className="input-group mb-3">
                          <input
                            min={0.01}
                            step={0.01}
                            value={hydraStaked}
                            onChange={(e) => setHydraStaked(e.target.value)}
                            type="number"
                            className="form-control"
                            aria-label="Amount of HYDRA you want to stake"
                            placeholder="Amount of HYDRA you want to stake?"
                            aria-describedby="basic-addon2"
                          />
                          <div className="input-group-append">
                            <span
                              className="input-group-text"
                              id="basic-addon2"
                            >
                              HYDRA
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 text-right">
                    <div className="bg-light p-3 rounded h-100">
                      <h6>
                        Expected income
                        <br />
                        per day
                      </h6>
                      {loadingHydraInfo || loadingHydraPrice ? (
                        <div class="spinner-grow" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <>
                          <h4>
                            {dailyIncome.toFixed(4)}
                            <br />
                            HYDRA
                          </h4>
                          <p className="text-secondary font-weight-bold">
                            (US${dailyIncomeInUSD.toFixed(2)})
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-6 col-md-3 text-right">
                    <div className="bg-light p-3 rounded h-100">
                      <h6>Annual Percentage Return</h6>
                      {loadingHydraInfo || loadingHydraPrice ? (
                        <div class="spinner-grow" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <h4 className="mt-4">{apy.toFixed(2)} %</h4>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row bg-light p-3 rounded text-center">
                  <p className="text-center mx-auto">
                    Check out full fledged staking calulator to make more
                    indepth computations on staking.
                  </p>
                  <Link to="/staking-calculator" class="default-btn mx-auto">
                    <i className="flaticon-right"></i>
                    Goto Staking Calculator
                    <span></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleCalculator;
