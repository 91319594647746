import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import Introduction from "../components/StakingEconomy/Introduction";
import TheNewEconomy from "../components/StakingEconomy/TheNewEconomy";
import StakingIncome from "../components/StakingEconomy/StakingIncome";
import StartProject from "../components/Common/StartProjectTwo";
import HowItWorks from "../components/StakingEconomy/HowItWorks";
import SimpleCalculator from "../components/StakingEconomy/SimpleCalculator";
import { Helmet } from "react-helmet";

const StakingEconomy = ({ location }) => {
  return (
    <Layout>
      <Helmet>
        <title>Staking Economy | HydraChain</title>
        <link rel="canonical" href={`https://hydrachain.org/${location.pathname}`} />
      </Helmet>
      <Navbar />
      <PageBanner pageTitle="Staking Economy" homePageText="Home" homePageUrl="/" activePageText="Staking Economy" />
      <SimpleCalculator></SimpleCalculator>
      <Introduction />
      <TheNewEconomy />
      <StakingIncome />
      <HowItWorks />
      <StartProject />
      <Footer />
    </Layout>
  );
};

export default StakingEconomy;
