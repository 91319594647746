import React from "react";
import starIcon from "../../assets/images/star-icon.png";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const WhyChooseUs = () => {
  return (
    <section className="how-its-work-area about-content ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-content content">
              <span className="sub-title">
                <img style={{ width: 30 }} src={starIcon} alt="banner" />
                Based on UTXO Model
              </span>
              <h2>Understanding how Staking works</h2>
              <p>
                Hydra is based on the UTXO model, which means that wallet balances consist of the combined value of all UTXOs stored in it. Having multiple UTXOs gives you the ability to perform parallel operations, particularly because of the independence each such UTXO carries. Understanding how
                the UTXO model works can help you optimize your staking rewards.
              </p>
              <Link to="https://docs.hydrachain.org/staking-hydra-coins/how-the-staking-works" target="_blank" className="default-btn">
                <i className="flaticon-right"></i>
                More Info
                <span></span>
              </Link>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 p-5">
            <div className="how-its-work-image">
              <StaticImage placeholder="blurred" src="../../assets/images/staking-works.png"></StaticImage>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
