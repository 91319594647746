import React from "react";
import starIcon from "../../assets/images/star-icon.png";
import shape2 from "../../assets/images/our-mission/our-mission-shape2.png";
import { StaticImage } from "gatsby-plugin-image";

const WhyChooseUs = () => {
  return (
    <section className="how-its-work-area about-content bg-f1f8fb ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-content content">
              <span className="sub-title">
                <img style={{ width: 30 }} src={starIcon} alt="banner" />
                Efficient &amp; Secure
              </span>
              <h2>Proof of Stake BlockChain</h2>
              <p>
                HYDRA is a proof of stake blockchain network. Staking is a critical activity for the maintenance of the blockchain. The more nodes are online, the better it is for the health of the chain, as it increases redundancy and at the same time strengthens the protective shield against
                attacks.
              </p>
              <p className="font-weight-bold">Here are few of the advantages of Staking</p>
              <ul className="about-list">
                <li>
                  <i className="flaticon-tick"></i>
                  Same APR for all stakers, regardless of HYDRA amount.
                  <img src={shape2} alt="about" />
                </li>
                <li>
                  <i className="flaticon-tick"></i>
                  Minimal computing power needed, accessible to everyone.
                  <img src={shape2} alt="about" />
                </li>
                <li>
                  <i className="flaticon-tick"></i>
                  Strong decentralization through hundreds of nodes.
                  <img src={shape2} alt="about" />
                </li>
                <li>
                  <i className="flaticon-tick"></i>
                  100x more secure against "51% attacks" compared to POW.
                  <img src={shape2} alt="about" />
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 p-5">
            <div className="how-its-work-image">
              <StaticImage placeholder="blurred" src="../../assets/images/proof-of-stake.png"></StaticImage>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
